import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import VueApexCharts from "vue3-apexcharts";
import Tooltip from "primevue/tooltip";

// Font Awesome Imports
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faPhone,
  faEnvelope,
  faUser,
  faCircleCheck,
  faCircleXmark,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

library.add(
  faPhone,
  faEnvelope,
  faUser,
  faBell,
  faFacebook,
  faTwitter,
  faCircleCheck,
  faCircleXmark,
  faAngleLeft
);
const app = createApp(App);

// Install Vue plugins
app.use(store);
app.use(router);
app.use(VueApexCharts);
app.use(Tooltip);

// Font Awesome Mount
app.component("font-awesome-icon", FontAwesomeIcon);

// Mount the Vue application
app.mount("#app");
