<template>
  <div class="h-screen w-screen">
    <TopBar />
    <div class="flex">
      <NavBar />
      <div class="pl-8 lg:pl-16 w-full mt-20">
        <div class="flex flex-col h-full mx-5">
          <div class="flex justify-end">
            <div
              v-if="userAlert"
              class="flex absolute right-5 items-center w-80 z-40 justify-start p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50"
              role="alert"
            >
              <div>
                <span class="font-medium">Success alert!</span> User saved
                successfully.
              </div>
            </div>
          </div>
          <div class="flex pl-3 lg:p-5 gap-x-2">
            <button
              @click="active_tab = 'user'"
              class="px-4 py-2 rounded-md text-center"
              :class="[
                active_tab === 'user' ? 'bg-[#2563EB] text-white' : 'border',
              ]"
            >
              User
            </button>
            <button
              @click="active_tab = 'sales_manager'"
              class="px-4 py-2 rounded-md text-center"
              :class="[
                active_tab === 'sales_manager'
                  ? 'bg-[#2563EB] text-white'
                  : 'border',
              ]"
            >
              Sales Manager
            </button>
            <button
              @click="active_tab = 'sales_executive'"
              class="px-4 py-2 rounded-md text-center"
              :class="[
                active_tab === 'sales_executive'
                  ? 'bg-[#2563EB] text-white'
                  : 'border',
              ]"
            >
              Sales Executive
            </button>
          </div>

          <!-- User Table -->
          <div
            class="text-left pl-3 pt-3 lg:p-5 w-full flex flex-col gap-y-5"
            v-if="active_tab == 'user'"
          >
            <div class="flex justify-between">
              <h1 class="text-2xl font-bold">Manage Users</h1>
              <button
                @click="is_popup = true"
                class="px-2 rounded-md bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-semibold"
              >
                &plus; Create
              </button>
            </div>
            <div class="overflow-x-scroll lg:overflow-x-hidden">
              <table class="w-full border text-left">
                <thead class="text-white">
                  <tr class="text-left">
                    <th class="py-2 px-2 bg-[#2563EB] rounded-l-lg">S.No</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Name</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Email</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Phone Number</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Roles</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Joined Date</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Status</th>
                    <th class="py-2 px-2 bg-[#2563EB] rounded-r-lg">Action</th>
                  </tr>
                </thead>
                <tbody class="w-full">
                  <template v-if="user.length > 0">
                    <tr
                      class="border text-left"
                      v-for="(id, index) in user"
                      :key="id"
                    >
                      <td class="p-2">{{ index + 1 }}</td>
                      <td class="p-2">
                        <div class="flex items-center gap-x-1">
                          <font-awesome-icon
                            icon="user"
                            class="text-blue-400"
                          />
                          {{ id.user }}
                        </div>
                      </td>
                      <td class="p-2 h-10 w-10">{{ id.email }}</td>
                      <td class="p-2">
                        <div class="flex items-center gap-x-1">
                          <font-awesome-icon
                            icon="phone"
                            class="text-blue-400"
                          />{{ id.phone }}
                        </div>
                      </td>
                      <td class="p-2">{{ id.user_role }}</td>
                      <td class="p-2">{{ id.joining_date }}</td>
                      <td
                        class="p-2"
                        :class="true ? 'text-green-400' : 'text-red-500'"
                      >
                        {{ id.active_status ? "Active" : "Inactive" }}
                      </td>
                      <td class="p-2">
                        <button
                          class="rounded-md px-2"
                          @click="toggleUpdateForm(id)"
                        >
                          Update
                        </button>
                      </td>
                    </tr></template
                  >
                  <template v-else
                    ><tr>
                      <td colspan="4">
                        No User Available..! Create a new user
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <CrmPagination
              :totalItems="user_count"
              :itemsPerPage="user_itemsPerPage"
              :currentPage="user_currentPage"
              @updatePage="updatePage"
            />
          </div>
          <!-- User Profile Update -->
          <div
            v-if="isUserProfileFormOpen"
            class="fixed top-10 inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          >
            <div class="border w-2/4 fixed top-10 bg-gray-100 p-5">
              <h1>Update User Information</h1>
              <form
                action=""
                @submit.prevent="updateProfile"
                class="text-left p-5 gap-y-3"
              >
                <div class="flex flex-col">
                  <label for="">Name</label>
                  <input
                    type="text"
                    name=""
                    class="border outline-none"
                    id="user"
                    v-model="userToUpdate.user"
                  />
                </div>
                <!-- <div class="flex flex-col">
                <label for="">Email</label>
                <input
                  type="text"
                  name=""
                  class="border outline-none"
                  id="user"
                  v-model="userToUpdate.email"
                />
              </div> -->
                <div class="flex flex-col">
                  <label for="">Role</label>
                  <select
                    name="user_role"
                    v-model="userToUpdate.user_role"
                    id="user_role"
                  >
                    <!-- <option value="admin">Admin</option> -->
                    <option
                      v-for="role in role_list"
                      :key="role.id"
                      :value="role.name"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-col">
                  <label for="">First Name</label>
                  <input
                    type="text"
                    name=""
                    class="border outline-none"
                    id="first_name"
                    v-model="userToUpdate.first_name"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="">Last Name</label>
                  <input
                    type="text"
                    name=""
                    class="border outline-none"
                    id="last_name"
                    v-model="userToUpdate.last_name"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="">Phone</label>
                  <input
                    type="tel"
                    name=""
                    class="border outline-none"
                    id="phone"
                    v-model="userToUpdate.phone"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="">Address</label>
                  <textarea
                    name=""
                    class="border outline-none"
                    id="address"
                    cols="30"
                    rows="1"
                    v-model="userToUpdate.address"
                  ></textarea>
                </div>
                <div class="flex flex-col">
                  <label for="">Joining Date</label>
                  <input
                    type="date"
                    name=""
                    class="border outline-none"
                    id="joining_date"
                    v-model="userToUpdate.joining_date"
                  />
                </div>
                <div class="flex flex-col">
                  <label for="">Profile Pic</label>
                  <input
                    type="file"
                    name=""
                    class="border outline-none"
                    id="profile_pic"
                    @change="onFileChange"
                  />
                </div>
                <div class="flex gap-x-1">
                  <label for="">Active Status</label>
                  <input
                    type="checkbox"
                    name=""
                    class="border outline-none"
                    id="active_status"
                    v-model="userToUpdate.active_status"
                  />
                </div>
                <div class="w-full flex justify-around">
                  <button
                    class="rounded-lg px-2"
                    @click="isUserProfileFormOpen = false"
                  >
                    Close
                  </button>
                  <button type="submit" class="rounded-lg px-2">Update</button>
                </div>
              </form>
            </div>
          </div>

          <!-- Sales Manager Table -->
          <div
            class="text-left pl-3 pt-3 lg:p-5 w-full flex flex-col gap-y-5"
            v-if="active_tab == 'sales_manager'"
          >
            <div class="flex justify-between">
              <h1 class="text-2xl font-bold">Sales Manager</h1>
              <!-- <button
              @click="is_popup = true"
              class="px-2 rounded-md bg-[#54B9EA] text-white font-semibold"
            >
              &plus; Assign
            </button> -->
            </div>
            <div class="overflow-x-scroll lg:overflow-x-hidden">
              <table class="w-full border text-left">
                <thead class="p-5">
                  <tr class="text-white">
                    <th class="py-2 px-2 bg-[#2563EB] rounded-l-lg">S.No</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Name</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Email</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Phone Number</th>
                    <th class="py-2 px-2 bg-[#2563EB] rounded-r-lg">Agency</th>
                  </tr>
                </thead>
                <tbody class="w-full" v-if="managerData != null">
                  <template v-if="managerData.length > 0">
                    <tr
                      class="border text-left"
                      v-for="(manager, index) in managerData"
                      :key="manager"
                    >
                      <td class="p-2">{{ index + 1 }}</td>
                      <td class="p-2">
                        <div class="flex items-center gap-x-1">
                          <font-awesome-icon
                            icon="user"
                            class="text-blue-400"
                          />{{ manager.user_profile }}
                        </div>
                      </td>
                      <td class="p-2">
                        <div class="flex items-center gap-x-1">
                          <font-awesome-icon
                            icon="envelope"
                            class="text-blue-400"
                          />{{ manager.email }}
                        </div>
                      </td>
                      <td class="p-2">
                        <div class="flex items-center gap-x-1">
                          <font-awesome-icon
                            icon="phone"
                            class="text-blue-400"
                          />+91
                          {{ manager.phone }}
                        </div>
                      </td>
                      <td class="p-2">WSI</td>
                    </tr></template
                  >
                  <template v-else>
                    <tr class="p-2">
                      <td class="p-2">No Manager Available here..!</td>
                      <td class="p-2">Create a Manager</td>
                    </tr>
                  </template>
                </tbody>

                <!-- <tbody v-else>
                <tr>
                  <td class="row-span-5">No Data Available</td>
                </tr>
              </tbody> -->
              </table>
            </div>
            <CrmPagination
              :totalItems="manager_count"
              :itemsPerPage="manager_itemsPerPage"
              :currentPage="man_currentPage"
              @updatePage="updatePage"
            />
          </div>

          <!-- Sales Executive Table -->
          <div
            class="text-left pl-3 pt-3 lg:p-5 w-full flex flex-col gap-y-5"
            v-if="active_tab == 'sales_executive'"
          >
            <div class="flex justify-between">
              <h1 class="text-2xl font-bold">Sales Executive</h1>
            </div>
            <div class="overflow-x-scroll lg:overflow-x-hidden">
              <table class="w-full border text-left">
                <!-- Table Headers -->
                <thead class="p-5">
                  <tr class="text-white">
                    <th class="py-2 px-2 bg-[#2563EB] rounded-l-lg">S.No</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Name</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Email</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Phone</th>
                    <th class="py-2 px-2 bg-[#2563EB]">Manager</th>
                    <!-- <template v-if="this.userInfo.user.role == 'admin'"> -->
                    <th class="py-2 px-2 bg-[#2563EB] rounded-r-lg">Action</th>
                    <!-- </template> -->
                  </tr>
                </thead>
                <!-- Table Body -->
                <tbody class="w-full">
                  <template v-if="executiveData && executiveData.length > 0">
                    <tr
                      v-for="(executive, index) in executiveData"
                      :key="executive.id"
                      class="border text-left"
                    >
                      <td class="p-2">{{ index + 1 }}</td>
                      <td class="p-2">
                        <div class="flex items-center gap-x-1">
                          <font-awesome-icon
                            icon="user"
                            class="text-blue-400"
                          />
                          <p>{{ executive.user_profile }}</p>
                        </div>
                      </td>
                      <td class="p-2">
                        <div class="flex items-center gap-x-1">
                          <font-awesome-icon
                            icon="envelope"
                            class="text-blue-400"
                          />
                          <p>{{ executive.email }}</p>
                        </div>
                      </td>
                      <td class="p-2">
                        <div class="flex items-center gap-x-1">
                          <font-awesome-icon
                            icon="phone"
                            class="text-blue-400"
                          />
                          <p>{{ executive.phone }}</p>
                        </div>
                      </td>
                      <td class="p-2">{{ executive.sale_manager }}</td>
                      <!-- <template v-if="this.userInfo.user.role == 'admin'"> -->
                      <td class="p-2">
                        <!-- Button to update -->
                        <button
                          class="rounded-lg px-2"
                          @click="toggleExectiveForm(executive)"
                        >
                          Update
                        </button>
                      </td>
                      <!-- </template> -->
                    </tr>
                  </template>
                  <template v-else>
                    <tr class="border">
                      <td colspan="" class="p-2 text-left">
                        No Executives to show...!
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <CrmPagination
              :totalItems="executive_count"
              :itemsPerPage="executive_itemsPerPage"
              :currentPage="exe_currentPage"
              @updatePage="updatePage"
            />
          </div>

          <div
            v-if="is_popup"
            class="w-full min-h-screen p-4 fixed top-0 left-0 bg-black bg-opacity-50 h-full overflow-y-auto flex items-center justify-center"
          >
            <!-- User Popup -->
            <div
              v-if="this.active_tab == 'user'"
              class="w-full md:w-[40%] bg-gray-100 rounded-lg font-semibold font-sans relative"
            >
              <button
                @click="is_popup = false"
                class="bg-red-600 px-2 text-white last:rounded-md absolute right-1 top-1 p-1 rounded-md"
              >
                X Close
              </button>

              <form
                action=""
                ref="user_post"
                @submit.prevent="CreateUser"
                class="p-5 flex flex-col gap-y-3"
              >
                <h1 class="text-xl">Add User</h1>
                <div class="flex flex-col items-start">
                  <label for="">Display Name</label>
                  <input
                    type="text"
                    id="username"
                    class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
                    required
                  />
                </div>

                <div class="flex flex-col items-start">
                  <label for="">Email</label>
                  <input
                    type="email"
                    id="email"
                    class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
                    required
                  />
                </div>

                <div class="flex flex-col items-start">
                  <label for="">Password</label>
                  <input
                    type="text"
                    id="password"
                    class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
                    required
                  />
                </div>

                <div class="flex flex-col items-start">
                  <label for="">Confirm Password</label>
                  <input
                    type="text"
                    id="confirm_password"
                    class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
                    required
                  />
                </div>

                <div class="w-full flex justify-center">
                  <button
                    type="submit"
                    class="w-[80px] py-1.5 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <!-- Sales Manager popup -->
            <div
              v-if="this.active_tab == 'sales_manager'"
              class="w-[40%] bg-[#338FDA] rounded-lg text-white font-serif relative"
            >
              <button
                @click="is_popup = false"
                class="bg-red-600 text-white last:rounded-md absolute right-1 top-1 p-1 rounded-md"
              >
                &cross; Close
              </button>

              <form action="" class="p-5 flex flex-col gap-y-3">
                <h1>Sales Manager</h1>
                <div class="flex flex-col items-start">
                  <label for="">Distributor</label>
                  <select
                    name=""
                    id=""
                    class="w-[60%] text-black p-1 rounded-md"
                    required
                  >
                    <option value="">Select a Distributor</option>
                    <option value="admin">WSI</option>
                    <option value="sales_manager">Chadura</option>
                    <option value="sales_executive">USDC</option>
                    <option value="project_manager">JAIN</option>
                    <option value="team_manager">Vignan</option>
                  </select>
                </div>

                <div class="flex flex-col items-start">
                  <label for="">Distributor</label>
                  <select
                    name=""
                    id=""
                    class="w-[60%] text-black p-1 rounded-md"
                    required
                  >
                    <option value="">Select a Distributor</option>
                    <option value="admin">WSI</option>
                    <option value="sales_manager">Chadura</option>
                    <option value="sales_executive">USDC</option>
                    <option value="project_manager">JAIN</option>
                    <option value="team_manager">Vignan</option>
                  </select>
                </div>

                <div class="w-full flex justify-center">
                  <button
                    type="submit"
                    class="w-[80px] py-1.5 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <!-- Sales Executive popup-->
            <div
              v-if="is_popup && active_tab == 'sales_executive'"
              class="w-[40%] bg-[#338FDA] rounded-lg text-white font-serif relative"
            >
              <button
                @click="is_popup = false"
                class="bg-red-600 text-white last:rounded-md absolute right-1 top-1 p-1 rounded-md"
              >
                &cross; Close
              </button>
              <!-- Sales Executive Update Form -->
              <form
                action=""
                @submit.prevent="updateSalesExecutive"
                class="p-5 flex flex-col gap-y-3"
              >
                <h1>Update Sales Executive</h1>
                <!-- Display Name -->
                <div class="flex flex-col items-start">
                  <label for="">Name</label>
                  <input
                    type="text"
                    class="px-2 rounded-md outline-none w-[80%] text-black"
                    id="sales_executive"
                    v-model="executiveToUpdate.user_profile"
                    disabled
                  />
                </div>
                <!-- Role -->
                <div class="flex flex-col items-start">
                  <label for="">Role</label>
                  <input
                    type="text"
                    class="px-2 rounded-md outline-none w-[80%] text-black"
                    id="user_role"
                    v-model="executiveToUpdate.user_role"
                    disabled
                  />
                </div>
                <!-- Select Manager -->
                <div class="flex flex-col items-start">
                  <label for="">Sales Manager</label>
                  <select
                    name="sale_manager"
                    class="px-2 rounded-md outline-none bg-white w-[80%] text-black"
                    v-model="assignData.sale_manager"
                    required
                  >
                    <option disabled value="">Select a Sales Manager</option>
                    <option
                      v-for="manager in managerData"
                      :key="manager.id"
                      :value="manager.username"
                    >
                      {{ manager.user_profile }}
                    </option>
                  </select>
                </div>
                <!-- Created By (from local storage) -->
                <div class="flex flex-col items-start">
                  <label for="">Created By</label>
                  <input
                    type="text"
                    class="px-2 rounded-md outline-none bg-white w-[80%] text-black"
                    id="created_by"
                    :value="createdByName"
                    disabled
                  />
                </div>
                <!-- Submit Button -->
                <div class="w-full flex justify-center">
                  <button type="submit" class="bg-gray-600 w-64 rounded-md">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import NavBar from "@/components/NavBar.vue";
import CrmPagination from "@/components/CrmPagination.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "ManagementView",
  components: {
    TopBar,
    NavBar,
    FontAwesomeIcon,
    CrmPagination,
  },
  data() {
    return {
      active_tab: localStorage.getItem("activeTab") || "user",
      is_popup: false,
      userAlert: false,
      isUserProfileFormOpen: false,
      role_count: "",
      role_list: [],
      assignData: [
        {
          sales_executive: "",
          role: "",
          sale_manager: "",
          created_by: this.userInfo,
        },
      ],

      user: [],
      user_count: "",
      userData: [
        {
          user: "",
          email: "",
          first_name: "",
          last_name: "",
          user_role: "",
          phone: "",
          address: "",
          joining_date: "",
          active_status: false,
          profile_pic: null,
        },
      ],
      user_currentPage: 1,
      userToUpdate: {},
      user_itemsPerPage: 0,

      manager_count: "",
      managerData: [],
      man_currentPage: 1,
      manager_itemsPerPage: 0,

      executiveData: [],
      executive_count: 0,
      exe_currentPage: 1,
      executive_itemsPerPage: 0,
      executiveToUpdate: {},
      user_role: "",
    };
  },
  created() {
    this.DisplayUser(this.user_currentPage);
    this.roles();
  },
  mounted() {
    this.managerList(this.man_currentPage);
    this.executiveList(this.exe_currentPage);
  },
  watch: {
    active_tab(newTab) {
      localStorage.setItem("activeTab", newTab);
    },
  },
  computed: {
    ...mapGetters(["getError", "isAuthenticated", "getToken"]),
    createdByName() {
      return localStorage.getItem("userInfo");
    },
    totalPages() {
      if (this.active_tab === "user") {
        return Math.ceil(this.user_count / this.user_itemsPerPage);
      } else if (this.active_tab === "sales_manager") {
        return Math.ceil(this.manager_count / this.manager_itemsPerPage);
      } else {
        return Math.ceil(this.executive_count / this.executive_itemsPerPage);
      }
    },
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];
      this.userToUpdate.profile_pic = file;
    },
    toggleUpdateForm(userData) {
      this.userToUpdate = userData;
      this.userToUpdateId = userData.uuid;
      console.log(this.userToUpdate);
      this.isUserProfileFormOpen = true;
    },
    toggleExectiveForm(assignData) {
      this.executiveToUpdate = assignData;
      this.salesExecutiveId = assignData.id;
      this.is_popup = true;
      console.log("exe form", this.executiveToUpdate);
    },
    // DisplayUser() {
    //   let token = this.getToken;
    //   axios
    //     .get(`${process.env.VUE_APP_API}/api/profiles`, {
    //       headers: {
    //         Authorization: `Token ${token}`,
    //       },
    //     })
    //     .then((response) => {
    //       this.user = response.data.data;
    //       console.log(this.user);
    //     });
    // },
    async DisplayUser(val) {
      const token = this.getToken;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/api/profiles`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
            params: {
              page: val,
            },
          }
        );

        if (response.data.status === 200) {
          this.user = response.data.data;
          this.user_count = response.data.count;
          this.user_itemsPerPage = response.data.page_size;
          this.user_currentPage = val;
          console.log("Leads:", this.user_itemsPerPage);
          console.log("Current Page:", this.user_currentPage);
        } else {
          console.error("Unexpected status code:", response.data.status);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },

    roles() {
      let token = this.getToken;
      axios
        .get(`${process.env.VUE_APP_API}/api/roles`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          this.role_list = response.data.data;
          console.log(this.role_list);
        });
    },

    async CreateUser(el) {
      const token = localStorage && localStorage.getItem("Token");

      const jsonData = {
        username: el.target.elements.username.value,
        email: el.target.elements.email.value,
        password: el.target.elements.password.value,
        confirm_password: el.target.elements.confirm_password.value,
      };

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API}/api/registers/`,
          jsonData,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        this.is_popup = false;
        this.userAlert = true;
        console.log("Lead saved:", response.data);
        this.DisplayUser(this.user_currentPage);
        setTimeout(() => {
          this.userAlert = false;
        }, 3000);

        console.log("No error occurred");
      } catch (error) {
        console.error("Error saving lead:", error);
      }
    },
    async updateProfile(el) {
      const token = localStorage && localStorage.getItem("Token");
      const userIdToUpdate = this.userToUpdateId;

      const formData = new FormData();
      formData.append("user_role", el.target.elements.user_role.value);
      formData.append("first_name", el.target.elements.first_name.value);
      formData.append("last_name", el.target.elements.last_name.value);
      formData.append("phone", el.target.elements.phone.value);
      formData.append("address", el.target.elements.address.value);
      formData.append("joining_date", el.target.elements.joining_date.value);
      formData.append(
        "active_status",
        el.target.elements.active_status.checked ? "true" : "false"
      );

      if (el.target.elements.profile_pic.files.length > 0) {
        formData.append("profile_pic", el.target.elements.profile_pic.files[0]);
      }

      try {
        const response = await axios.patch(
          `${process.env.VUE_APP_API}/api/profiles/${userIdToUpdate}/`,
          formData,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Profile updated:", response.data);
        this.DisplayUser();
        this.executiveList();

        this.isUserProfileFormOpen = false;
        console.log("No error occurred");
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    },
    //manager list
    // managerList() {
    //   let token = this.getToken;
    //   axios
    //     .get(`${process.env.VUE_APP_API}/api/sales-manager-list/`, {
    //       headers: {
    //         Authorization: `Token ${token}`,
    //       },
    //     })
    //     .then((response) => {
    //       this.managerData = response.data.data;
    //     });
    // },
    async managerList(val2) {
      const token = this.getToken;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/api/sales-manager-list/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
            params: {
              page: val2,
            },
          }
        );

        if (response.data.status === 200) {
          this.managerData = response.data.data;
          this.manager_count = response.data.count;
          this.manager_itemsPerPage = response.data.page_size;
          this.man_currentPage = val2;
          console.log("Leads:", this.man);
          console.log("Current Page:", this.man_currentPage);
        } else {
          console.error("Unexpected status code:", response.data.status);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },

    //executive role_list
    // executiveList() {
    //   let token = this.getToken;
    //   axios
    //     .get(`${process.env.VUE_APP_API}/api/sales-executive-list/`, {
    //       headers: {
    //         Authorization: `Token ${token}`,
    //       },
    //     })
    //     .then((response) => {
    //       console.log(response.data);
    //       this.executiveData = response.data.data;
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching executive roles:", error);
    //     });
    // },
    async executiveList(val3) {
      const token = this.getToken;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/api/sales-executive-list/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
            params: {
              page: val3,
            },
          }
        );

        if (response.data.status === 200) {
          this.executiveData = response.data.data;
          this.executive_count = response.data.count;
          this.executive_itemsPerPage = response.data.page_size;
          this.exe_currentPage = val3;
          console.log("executive:", this.executive_count);
          console.log("Current Page:", this.user_currentPage);
        } else {
          console.error("Unexpected status code:", response.data.status);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },

    async updatePage(page) {
      if (this.active_tab === "user") {
        if (page > 0 && page <= this.totalPages) {
          this.user_currentPage = page;
          this.DisplayUser(page);
        }
      } else if (this.active_tab === "sales_manager") {
        if (page > 0 && page <= this.totalPages) {
          this.man_currentPage = page;
          this.managerList(page);
        }
      } else {
        if (page > 0 && page <= this.totalPages) {
          this.exe_currentPage = page;
          this.executiveList(page);
        }
      }
    },

    async updateSalesExecutive(el) {
      const token = this.getToken;
      const userIdToUpdate = this.salesExecutiveId;

      const formData = new FormData(el.target);

      formData.append("created_by", 58);

      try {
        const response = await axios.patch(
          `${process.env.VUE_APP_API}/api/salesexecutives/${userIdToUpdate}/`,
          formData,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Sales executive updated:", response.data);
        console.log("No error occurred");
        this.is_popup = false;
      } catch (error) {
        console.error("Error updating sales executive:", error);
      }
    },
  },
};
</script>
