<template>
  <div class="w-screen h-full relative">
    <!-- <div class="w-full h-full fixed top-0 left-0 bg-black opacity-10"></div> -->
    <div
      class="absolute h-full w-full lg:pt-20 lg:pl-20 !flex !justify-center !items-center"
    >
      <div
        class="w-[95%] h-[90%] bg-gray-100 text-gray-700 font-semibold font-sans p-7 lg:px-16 lg:py-12 rounded-lg mx-auto overflow-y-scroll"
      >
        <!-- Form -->
        <div class="flex justify-between">
          <p class="text-[#2563EB] text-3xl font-bold">Lead Add</p>
          <button
            @click="closeLeadForm"
            class="bg-red-600 text-white w-[80px] py-1.5 rounded-md"
          >
            Close X
          </button>
        </div>

        <form
          @submit.prevent="saveChanges"
          action=""
          class="text-gray-700 md:flex md:flex-col gap-5 pt-5"
        >
          <div class="flex flex-col lg:flex-row gap-5 w-full py-2">
            <!-- first name -->
            <div class="w-full">
              <input
                type="text"
                v-model="formData.name"
                v-on:input="validateInput"
                placeholder="First Name *"
                required
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
            <!-- last name  -->
            <div class="w-full">
              <input
                type="text"
                v-model="formData.last_name"
                v-on:input="validateInputName"
                placeholder="Last Name"
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
            <div class="w-full">
              <input
                type="email"
                v-model="formData.email"
                required
                placeholder="email *"
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
            <!-- phone number  -->
            <div class="flex w-full">
              <select
                v-model="formData.selectedCountryCode"
                class="w-20 bg-white text-center border border-gray-300 text-gray-900 rounded-lg pl-3 py-3"
              >
                <option
                  v-for="country in countryCodes"
                  :key="country.code"
                  :value="country.code"
                >
                  {{
                    formData.selectedCountryCode === country.code
                      ? country.code
                      : `${country.name} (${country.code})`
                  }}
                </option>
              </select>
              <input
                type="tel"
                name="mobile"
                id="Mobile"
                autocomplete="off"
                maxlength="10"
                pattern="[0-9]*"
                placeholder="mobile *"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                required
                v-model="formData.phone_number"
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
          </div>
          <div class="flex flex-col lg:flex-row gap-5 w-full py-2">
            <div class="w-full">
              <input
                type="text"
                v-model="formData.lead_company"
                placeholder="company *"
                required
                class="text-gray-900 border border-gray-300 focus:outline-none py-3 px-3 rounded-lg w-full"
              />
            </div>
            <div class="w-full">
              <select
                v-model="formData.lead_industry_type"
                placeholder="industry *"
                required
                class="border border-gray-300 text-gray-900 bg-white focus:outline-none rounded-lg py-3 px-3 w-full"
              >
                <option value="" disabled selected>
                  Select an Industry Type *
                </option>
                <option
                  v-for="industry in industryTypes"
                  :key="industry.id"
                  :value="industry.id"
                  class="text-gray-900"
                >
                  {{ industry.name }}
                </option>
              </select>
            </div>
            <div class="w-full">
              <select
                v-model="formData.country"
                placeholder="industry *"
                class="border border-gray-300 text-gray-900 bg-white focus:outline-none rounded-lg py-3 px-3 w-full"
              >
                <option value="" disabled selected>Select a Country</option>
                <option value="India">India</option>
              </select>
            </div>
            <div class="w-full">
              <select
                v-model="formData.state"
                @change="onStateChange"
                class="border border-gray-300 text-gray-900 bg-white focus:outline-none rounded-lg py-3 px-3 w-full"
              >
                <option value="" disabled selected>Select a State</option>
                <option
                  class="text-gray-900"
                  v-for="state in stateList"
                  :key="state.id"
                  :value="state.state"
                >
                  {{ state.state }}
                </option>
              </select>
            </div>
          </div>
          <div class="flex flex-col lg:flex-row gap-5 w-full py-2">
            <div class="w-full">
              <select
                name=""
                id=""
                :disabled="!cities.length"
                v-model="formData.city"
                placeholder="city "
                class="border border-gray-300 bg-white text-gray-900 w-full focus:outline-none rounded-lg py-3 px-3"
              >
                <option value="">Select a City</option>
                <option v-for="city in cities" :key="city" :value="city">
                  {{ city }}
                </option>
              </select>
            </div>
            <div class="flex w-full">
              <select
                v-model="formData.selectedCountryCode"
                class="w-20 bg-white text-center border border-gray-300 text-gray-900 rounded-lg w-20 text-center"
              >
                <option
                  v-for="country in countryCodes"
                  :key="country.code"
                  :value="country.code"
                >
                  {{
                    formData.selectedCountryCode === country.code
                      ? country.code
                      : `${country.name} (${country.code})`
                  }}
                </option>
              </select>
              <input
                type="tel"
                name="mobile"
                id="Mobile"
                autocomplete="off"
                maxlength="10"
                pattern="[0-9]*"
                placeholder="alternate mobile "
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                v-model="formData.alt_phone_number"
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
            <div class="w-full">
              <select
                v-model="formData.lead_pri_product"
                placeholder="Interest Product *"
                required
                class="border border-gray-300 text-gray-900 bg-white focus:outline-none rounded-lg py-3 px-3 w-full"
              >
                <option value="" disabled selected>
                  Select a Interest Product *
                </option>
                <option
                  v-for="platform in platforms"
                  :key="platform.id"
                  :value="platform.id"
                  class="text-gray-900"
                >
                  {{ platform.name }}
                </option>
              </select>
            </div>
            <div class="w-full">
              <input
                type="text"
                v-model="formData.otherProducts"
                placeholder="Other Interested  Products"
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
          </div>
          <div class="flex flex-col lg:flex-row gap-5 w-full py-2">
            <div class="w-full">
              <input
                type="text"
                v-model="formData.poc_name"
                v-on:input="validatePoc"
                placeholder="POC Name *"
                required
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>

            <div class="w-full">
              <input
                type="email"
                v-model="formData.poc_email"
                required
                placeholder="POC email *"
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
            <div class="flex w-full">
              <select
                v-model="formData.selectedCountryCode"
                class="w-20 bg-white text-center border border-gray-300 text-gray-900 rounded-lg pl-3 py-3"
              >
                <option
                  v-for="country in countryCodes"
                  :key="country.code"
                  :value="country.code"
                >
                  {{
                    formData.selectedCountryCode === country.code
                      ? country.code
                      : `${country.name} (${country.code})`
                  }}
                </option>
              </select>
              <input
                type="tel"
                name="mobile"
                id="Mobile"
                autocomplete="off"
                pattern="[0-9]*"
                placeholder="POC mobile *"
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                required
                v-model="formData.poc_phone"
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
            <div class="w-full">
              <input
                type="text"
                v-model="formData.poc_role"
                v-on:input="validateRole"
                placeholder="POC role"
                class="border border-gray-300 text-gray-900 focus:outline-none rounded-lg py-3 px-3 w-full"
              />
            </div>
          </div>
          <div class="flex flex-col lg:flex-row gap-2">
            <div class="flex flex-col items-start gap-2 w-full py-2">
              <label for="address">Address</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                v-model="formData.address"
                class="w-full p-3 rounded-lg border border-gray-300 text-gray-900 focus:outline-none"
              ></textarea>
            </div>
            <div class="flex flex-col items-start gap-2 w-full py-2">
              <label for="message">Message</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                v-model="formData.message"
                class="w-full p-3 rounded-lg border border-gray-300 text-gray-900 focus:outline-none"
              ></textarea>
            </div>
          </div>

          <div class="flex gap-x-3 justify-end md:mt-0 pt-3">
            <button
              type="submit"
              class="w-[80px] py-1.5 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
            >
              Save
            </button>
          </div>
        </form>
        <div v-if="errorMessage">
          <span class="text-md text-red-500">{{ errorMessage }}</span>
        </div>
        <div v-if="leadMessage">
          <span class="text-md">{{ leadMessage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import stateMixins from "@/mixins/stateMixins";
import countryCodeMixins from "@/mixins/countryCodeMixins";
export default {
  name: "LeadForm",
  mixins: [stateMixins, countryCodeMixins],
  props: {
    is_popup: Boolean,
  },
  data() {
    return {
      active_tab: "user",
      cities: "",
      industryTypes: [],
      statusList: [],
      platforms: [],
      errorMessage: "",
      leadMessage: "",
      formData: {
        name: "",
        last_name: "",
        email: "",
        phone_number: "",
        lead_company: "",
        lead_industry_type: "",
        country: "",
        state: "",
        city: "",
        alt_phone_number: "",
        address: "",
        lead_pri_product: "",
        otherProducts: "",
        message: "",
        source: "",
        poc_name: "",
        poc_email: "",
        poc_phone: "",
        poc_role: "",
        owner: 1,
        selectedCountryCode: "+91",
        lead_status: 1,
        // lead_platform: "",
      },
    };
  },

  async mounted() {
    await this.industryType();
    await this.statusLead();
    await this.platform();
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getToken", "getError"]),
  },
  methods: {
    async industryType() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/industry-types/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.industryTypes = resp.data.data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async statusLead() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/lead-status-list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.statusList = resp.data.data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    async platform() {
      await axios
        .get(
          `${process.env.VUE_APP_API}/api/lead-platforms/?organization__slug=galecta`
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.platforms = resp.data.data;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    closeLeadForm() {
      this.$emit("closeLeadForm");
    },
    onStateChange() {
      console.log("onStateChange ::::::::::::::::", this.formData);
      const state = this.stateList.find((s) => s.state === this.formData.state);
      this.cities = state ? state.districts : [];
      this.formData.city = "";
    },
    validateInput(event) {
      const regex = /^[A-Za-z\s]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^A-Za-z\s]/g, "");
      }
      this.formData.name = event.target.value;
    },
    validateInputName(event) {
      const regex = /^[A-Za-z\s]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^A-Za-z\s]/g, "");
      }
      this.formData.last_name = event.target.value;
    },
    validatePoc(event) {
      const regex = /^[A-Za-z\s]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^A-Za-z\s]/g, "");
      }
      this.formData.poc_name = event.target.value;
    },
    validateRole(event) {
      const regex = /^[A-Za-z\s]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^A-Za-z\s]/g, "");
      }
      this.formData.poc_role = event.target.value;
    },
    async saveChanges() {
      const token = localStorage.getItem("Token");
      console.log(token, "New Token from savechanges");

      console.log("FormData:", this.formData);

      await axios
        .post(
          `${process.env.VUE_APP_API}/api/lead-details/post/`,
          this.formData,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((resp) => {
          if (resp.status == 200) {
            // if (
            //   resp.status == 200 &&
            //   resp.success == true &&
            //   resp.message == "Lead created Successfully"
            // ) {
            //   this.leadMessage = resp.message;
            // }
            this.leadMessage = resp.data.message;
            console.log(
              "resp.data.message success value : ",
              resp.data.message
            );
            this.$emit("formSubmittedSuccessfully");
            setTimeout(() => {
              this.closeLeadForm();
            }, 3000);
          } else {
            this.errorMessage = resp.data.message;
          }
        })
        .catch((err) => {
          console.error("Error saving lead:", err);
        });
    },
    created() {
      this.saveChanges();
    },
  },
};
</script>
