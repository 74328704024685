<template>
  <div>
    <div class="w-full md:h-[60px] lg:h-[80px]">
      <TopBar />
    </div>

    <div class="flex h-full w-full">
      <div class="w-[60px] lg:w-[80px] h-full z-20">
        <NavBar />
      </div>
      <div class="w-full h-full pl-16 pt-10 md:px-5 lg:px-8">
        <div
          class="flex flex-col md:flex-row pl-2 gap-2 lg:gap-5 lg:p-4 2xl:p-6 bg-white rounded-lg pt-10 md:pt-0 md:sticky md:top-[80px] z-10"
        >
          <div class="w-full flex-wrap lg:w-[50%] flex gap-2 lg:gap-5">
            <router-link
              :to="{ name: 'campagin' }"
              class="px-2 w-[45%] sm:w-auto 2xl:px-4 rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Campaign',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Campaign',
              }"
            >
              Campaign
            </router-link>
            <router-link
              :to="{ name: 'recipientList' }"
              class="px-2 2xl:px-4 w-[45%] sm:w-auto rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Campaign list',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Campaign list',
              }"
            >
              Campaign List
            </router-link>
            <router-link
              :to="{ name: 'recipient' }"
              class="px-2 2xl:px-4 w-[45%] sm:w-auto rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Recipient',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Recipient',
              }"
            >
              Recipient
            </router-link>
            <router-link
              :to="{ name: 'emailTemplate' }"
              class="px-2 2xl:px-4 rounded-lg w-[45%] sm:w-auto flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Email Template',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Email Template',
              }"
            >
              Email Template
            </router-link>
          </div>
          <div
            class="w-full flex-col md:flex-row md:items-center lg:w-[50%] flex gap-3 2xl:gap-5 justify-end"
          >
            <div class="">
              <form
                @submit.prevent="serachRecipientList(recipientListSearch)"
                class="flex gap-3 items-center lg:gap-x-5"
              >
                <input
                  type="text"
                  class="rounded-lg w-48 sm:w-auto border px-2 py-2 outline-none capitalize"
                  placeholder="Search Names Here"
                  name=""
                  v-model="recipientListSearch"
                  id="search_recipient_list"
                />
                <button
                  type="submit"
                  class="bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-2 2xl:px-4 h-[36px] 2xl:h-[42px] flex items-center rounded-lg"
                >
                  Submit
                </button>
              </form>
            </div>

            <button
              @click="addCampaign"
              class="bg-gradient-to-r w-16 sm:w-auto from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-2 2xl:px-4 h-[36px] 2xl:h-[42px] flex items-center rounded-lg"
            >
              Add +
            </button>
          </div>
        </div>

        <!-- Recipient List   -->
        <div
          v-if="campaginAdding && activeCampaign == 'Campaign list'"
          class="fixed inset-0 z-40 flex justify-end"
        >
          <div class="bg-[#2563EB] overflow-y-scroll p-8">
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-xl font-semibold text-white">Add Recipient</h2>
              <button
                @click="closeOffcanvas"
                class="text-white bg-red-500 px-4 py-2 rounded-lg"
              >
                X Close
              </button>
            </div>
            <form
              action=""
              @submit.prevent="addRecipient"
              class="bg-gray-100 p-4 flex flex-col gap-4 rounded-lg"
            >
              <div class="flex flex-col text-start">
                <label for="updatedName">Add Campaign</label>
                <select
                  class="border focus:outline-none rounded-md px-3 py-2 w-full bg-white"
                  name="recipientList"
                  id="recipientList"
                >
                  <option value="" disabled selected>Add Campaign</option>
                  <option
                    v-for="campaign in campaignList"
                    :key="campaign.id"
                    :value="campaign.id"
                  >
                    {{ campaign.name }}
                  </option>
                </select>
              </div>
              <div class="flex flex-col text-start">
                <label for="updatedEmail">Add Recipient</label>
                <select
                  class="border focus:outline-none rounded-md px-3 py-2 w-full bg-white"
                  name="recipientList"
                  id="recipientList"
                  v-model="recipientAddition.recipient"
                >
                  <option value="" disabled selected>Add Recipient</option>
                  <option
                    v-for="recipients in individualList"
                    :key="recipients.id"
                    :value="recipients.id"
                  >
                    {{ recipients.email }}
                  </option>
                </select>
              </div>

              <div class="flex justify-center pt-3">
                <button
                  type="submit"
                  class="py-2 w-40 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                >
                  Submit
                </button>
              </div>
            </form>
            <div class="flex items-center justify-center pt-3">
              <div class="w-full border-t border-black h-0"></div>
              <div class="px-3 text-black">or</div>
              <div class="w-full border-t border-black h-0"></div>
            </div>
            <form
              @submit.prevent="bulkUpload"
              class="bg-gray-100 p-4 flex flex-col gap-4 rounded-lg"
            >
              <h1 class="text-lg">Bulk Upload</h1>
              <div class="flex flex-col text-start">
                <label for="updatedName">Select Campaign</label>
                <select
                  class="border focus:outline-none rounded-md px-3 py-2 w-full bg-white"
                  name=""
                  id="campaign_id"
                  required
                >
                  <option value="" disabled selected>Select Campaign</option>
                  <option
                    v-for="campaign in campaignList"
                    :key="campaign.id"
                    :value="campaign.id"
                  >
                    {{ campaign.name }}
                  </option>
                </select>
              </div>
              <div class="flex flex-col text-start">
                <label for="updatedName">Upload the excel file</label>
                <input
                  type="file"
                  name=""
                  id="recipient_upload"
                  accept="xlsx"
                  required
                />
              </div>
              <div class="flex justify-center pt-3">
                <button
                  type="submit"
                  class="py-2 w-40 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="w-full pt-8 overflow-x-scroll lg:overflow-x-hidden">
          <!-- Recipient list start  -->
          <div v-if="activeCampaign === 'Campaign list'" class="max-h-full">
            <table class="w-full h-full whitespace-nowrap mb-4">
              <thead class="text-white">
                <tr class="text-left">
                  <th class="py-4 px-4 bg-[#2563EB] rounded-l-lg">S.No</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Campaign</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Recipient</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Sent</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Opened</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Clicked</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Sent at</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Opened at</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Clicked at</th>
                  <th class="py-4 px-4 bg-[#2563EB] rounded-r-lg">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="message">
                  <td></td>
                  <td class="px-4 py-2 text-left">{{ message }}</td>
                </tr>
                <tr
                  v-else
                  class="text-left border-b"
                  v-for="(recipient, index) in recipientList"
                  :key="index"
                >
                  <td class="px-4 py-2">
                    {{ index + 1 + (currentPage - 1) * itemsPerPage }}
                  </td>
                  <td class="px-4 py-2">{{ recipient.campaign.name }}</td>
                  <td class="px-4 py-2">{{ recipient.recipient.email }}</td>
                  <td class="px-4 py-2">
                    <font-awesome-icon
                      v-if="recipient.sent"
                      :icon="['fas', 'circle-check']"
                      class="w-5 h-5 object-contain text-green-500"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'circle-xmark']"
                      class="w-5 h-5 object-contain text-red-500"
                    />
                  </td>
                  <td class="px-4 py-2">
                    <font-awesome-icon
                      v-if="recipient.opened"
                      :icon="['fas', 'circle-check']"
                      class="w-5 h-5 object-contain text-green-500"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'circle-xmark']"
                      class="w-5 h-5 object-contain text-red-500"
                    />
                  </td>
                  <td class="px-4 py-2">
                    <font-awesome-icon
                      v-if="recipient.clicked"
                      :icon="['fas', 'circle-check']"
                      class="w-5 h-5 object-contain text-green-500"
                    />
                    <font-awesome-icon
                      v-else
                      :icon="['fas', 'circle-xmark']"
                      class="w-5 h-5 object-contain text-red-500"
                    />
                  </td>
                  <td class="px-4 py-2">
                    <p v-if="recipient.sent_at">
                      {{ formatDate(recipient.sent_at) }}
                    </p>
                  </td>
                  <td class="px-4 py-2">
                    <p v-if="recipient.opened_at">
                      {{ formatDate(recipient.opened_at) }}
                    </p>
                  </td>
                  <td class="px-4 py-2">
                    <p v-if="recipient.clicked_at">
                      {{ formatDate(recipient.clicked_at) }}
                    </p>
                  </td>
                  <td class="whitespace-nowrap px-4 py-2 relative">
                    <div class="inline-flex items-center justify-center">
                      <button
                        @click="toggleEdit(recipient.id)"
                        class="btn size-8 rounded-full mt-1 p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="size-4.5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </button>
                      <div
                        class="paper absolute -left-[110px] top-4 w-[130px]"
                        v-show="popupId === recipient.id"
                      >
                        <div
                          class="popper-box rounded-md border border-slate-150 bg-white py-0 font-inter dark:border-navy-500 dark:bg-navy-700"
                        >
                          <ul>
                            <li @click="editRecipientList(recipient.id)">
                              <p
                                class="flex h-6 items-center px-3 pr-4 cursor-pointer font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 text-xs"
                              >
                                Edit
                              </p>
                            </li>
                            <li
                              @click="
                                deleteRecipientList(
                                  recipient.id,
                                  recipient.campaign.name
                                )
                              "
                            >
                              <p
                                class="flex h-6 items-center px-3 pr-4 cursor-pointer font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 text-xs"
                              >
                                Delete
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Recipient list end  -->
        </div>
        <CrmPagination
          :totalItems="recipient_count"
          :itemsPerPage="itemsPerPage"
          :currentPage="currentPage"
          @updatePage="updatePage"
        />
        <!-- delete recipient list -->
        <div
          v-if="deleteRecipientsList == true"
          class="z-50 flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-80"
        >
          <div
            class="!flex justify-center !items-center bg-white mx-auto w-[500px] h-[200px] rounded-lg relative !z-999 p-5 overflow-y-scroll pt-5 text-3xl font-bold text-black"
          >
            <div class="flex flex-col gap-3">
              <h3>Are You Sure Want To Delete</h3>
              <p class="text-xl text-gray-700">{{ campagin_name }}</p>
              <div class="flex justify-center gap-10 text-lg">
                <button
                  @click="deleteRecipientListstatus"
                  class="text-white bg-green-400 px-3 py-1.5 rounded-lg"
                >
                  Delete
                </button>
                <button
                  @click="deleteRecipientsList = false"
                  class="text-white bg-red-400 px-3 py-1.5 rounded-lg"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- edit recipient List   -->
        <div
          v-if="editRecipientListStatus == true"
          class="z-50 flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-80"
        >
          <div
            class="!flex justify-center !items-center bg-white mx-auto w-[80%] rounded-lg relative !z-999 p-5 overflow-y-scroll pt-5 text-3xl font-bold text-black"
          >
            <div class="p-8 w-full text-lg h-full">
              <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold text-black">
                  Update Recipient List
                </h2>
                <button
                  @click="editRecipientListStatus = false"
                  class="text-white bg-red-500 px-4 py-2 rounded-lg"
                >
                  Close
                </button>
              </div>
              <form
                action=""
                @submit.prevent="updateRecipientList"
                class="p-4 flex flex-col gap-4 rounded-lg bg-gray-100"
              >
                <div class="flex flex-col text-start">
                  <label for="updatedName">Campaign List</label>
                  <select
                    class="border focus:outline-none rounded-md px-3 py-2 w-full bg-white"
                    name="recipientList"
                    id="recipientList"
                    v-model="updateRecipientLists.campaign"
                  >
                    <option value="" disabled selected>Update Campaign</option>
                    <option
                      v-for="recipients in campaignList"
                      :key="recipients.id"
                      :value="recipients.id"
                    >
                      {{ recipients.name }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-col text-start">
                  <label for="updatedEmail">Recipient</label>
                  <select
                    class="border focus:outline-none rounded-md px-3 py-2 w-full bg-white"
                    name="recipientList"
                    id="recipientList"
                    v-model="updateRecipientLists.recipient"
                  >
                    <option value="" disabled selected>Update Recipient</option>
                    <option
                      v-for="recipients in individualList"
                      :key="recipients.id"
                      :value="recipients.id"
                    >
                      {{ recipients.email }}
                    </option>
                  </select>
                </div>

                <div class="flex justify-center pt-3">
                  <button
                    type="submit"
                    class="py-2 w-40 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div v-if="updateValue" class="py-3">
                <p class="text-lg font-semibold">{{ updateValue }}</p>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="flashMessage"
          class="fixed max-w-[320px] w-full bg-white border border-gray-200 shadow-xl bottom-2 right-2 p-6 rounded-md z-[500]"
        >
          {{ messageVal }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import TopBar from "@/components/TopBar.vue";
import CrmPagination from "@/components/CrmPagination.vue";
export default {
  name: "RecipientList",
  components: {
    NavBar,
    TopBar,
    CrmPagination,
  },
  data() {
    return {
      deleteRecipientsList: false,
      editRecipientListStatus: false,
      recipientlistToDelete: "",
      popupId: "",
      activeCampaign: "",
      campaginAdding: false,
      start: 0,
      end: 10,
      campaignList: [],
      recipient_count: "",
      recipientList: [],
      individualList: [],
      recipientAddition: {
        campaign: "",
        recipient: "",
      },
      currentPage: 1,
      itemsPerPage: 0,
      campagin_name: "",
      recipientListUpdate: [],
      recipientListIdToUpdate: "",
      updateRecipientLists: {
        campaign: "",
        recipient: "",
      },
      updateValue: "",
      message: "",
      flashMessage: false,
      isLoading: false,
      messageVal: "",
    };
  },
  async created() {
    await this.getRecipientList(this.currentPage);
    await this.getCampaign();
    await this.getIndividual();
    let url = this.$route.path;
    if (url == "/campagin-dashboard/recipient-list") {
      this.activeCampaign = "Campaign list";
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.recipient_count / this.itemsPerPage);
    },
    // paginatedRecipients() {
    //   if (this.recipientList.length > 0) {
    //     const start = (this.currentPage - 1) * this.itemsPerPage;
    //     const end = start + this.itemsPerPage;
    //     return this.recipientList.slice(start, end);
    //   }
    //   return [];
    // },
  },
  methods: {
    bulkUpload(el) {
      this.isLoading = true;
      let id = el.target.elements.campaign_id.value;
      let fileData = new FormData();
      fileData.append("file", el.target.elements.recipient_upload.files[0]);

      axios
        .post(
          `${process.env.VUE_APP_API}/api/bulk-upload/campaign-recipients/${id}/`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${this.$store.getters.getToken}`,
            },
          }
        )
        .then((response) => {
          this.flashMessage = true;
          this.isLoading = false;
          this.campaginAdding = false;
          this.messageVal = response.data.message;
          this.getRecipientList();
          setTimeout(() => {
            this.flashMessage = false;
          }, 6000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleEdit(id) {
      if (this.popupId === id) {
        this.popupId = null;
      } else {
        this.popupId = id;
      }
    },
    async getCampaign() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/campaign/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.campaignList = resp.data.data;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    // async getRecipientList() {
    //   await axios
    //     .get(`${process.env.VUE_APP_API}/api/campaign-recipient/list/`)
    //     .then((resp) => {
    //       if (resp.status == 200) {
    //         this.recipientList = resp.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("error : ", err);
    //     });
    // },
    async getRecipientList(val) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/api/campaign-recipient/list/`,
          {
            params: {
              page: val,
            },
          }
        );

        if (response.data.status === 200) {
          this.recipientList = response.data.data;
          this.recipient_count = response.data.count;
          this.itemsPerPage = response.data.page_size;
          this.currentPage = val;
          console.log("campaign list:", this.itemsPerPage);
          console.log("Current Page:", this.recipient_count);
        } else {
          console.error("Unexpected status code:", response.data.status);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },

    async updatePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        await this.getRecipientList(page);
      }
    },
    async getIndividual() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/recipient/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.individualList = resp.data.data;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    serachRecipientList(name) {
      axios
        .get(
          `${process.env.VUE_APP_API}/api/campaign-recipient/list/?search=${name}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            console.log("serachRecipientList response ::", resp);
            this.recipientList = resp.data.data;
          } else if (resp.data.status == 400) {
            this.message = resp.data.message;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    // delete campaign start
    deleteRecipientList(id, name) {
      this.deleteRecipientsList = true;
      this.campagin_name = name;
      this.recipientListToDelete = id;
    },
    deleteRecipientListstatus() {
      axios
        .delete(
          `${process.env.VUE_APP_API}/api/campaign-recipient/update-destroy/${this.recipientlistToDelete}/`
        )
        .then((resp) => {
          if (resp.status == 200) {
            if (this.recipientList.length === 1 && this.currentPage > 1) {
              this.currentPage--;
            }
            this.updatePage(this.currentPage);
            this.getRecipient(this.currentPage);
            this.popupId = "";
          }
        })
        .catch((err) => {
          console.log("error ", err);
        });
      this.deleteRecipientsList = false;
      this.campagin_name = "";
    },
    // delete campaign end
    editRecipientList(id) {
      this.recipientListUpdate = this.recipientList.filter(
        (recipientList) => id === recipientList.id
      )[0];
      this.updateRecipientLists = {
        campaign: this.recipientListUpdate.campaign.name,
        recipient: this.recipientListUpdate.recipient.email,
      };
      this.editRecipientListStatus = true;
      this.recipientListIdToUpdate = id;
    },
    updateRecipientList() {
      this.updateValue = "";
      axios
        .patch(
          `${process.env.VUE_APP_API}/api/campaign-recipient/update-destroy/${this.recipientListIdToUpdate}/`,
          this.updateRecipientLists
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.updateValue = resp.data.message;
            setTimeout(() => {
              this.getRecipient();
              this.popupId = null;
              this.editRecipientListStatus = false;
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("error ", err);
        });
    },
    // edit campaign end
    formatUpdateDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatDate(dateString) {
      if (!dateString) return "Invalid Date";
      const date = new Date(dateString);

      const dateOptions = { month: "short", day: "2-digit", year: "numeric" };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formattedDate = date.toLocaleDateString("en-IN", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-IN", timeOptions);

      return `${formattedDate} / ${formattedTime}`;
    },
    addCampaign() {
      this.campaginAdding = true;
    },
    addRecipient() {
      axios
        .post(
          `${process.env.VUE_APP_API}/api/campaign-recipient/post/`,
          this.recipientAddition
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.getRecipientList(this.currentPage);
            this.getCampaign();
            this.getIndividual();
            this.closeOffcanvas();
            this.popupId = "";
            console.log(
              "getRecipientList :::",
              this.getRecipientList,
              "getCampaign :::",
              this.getCampaign,
              "getIndividual :::",
              this.getIndividual
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

    closeOffcanvas() {
      this.campaginAdding = false;
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailValid = emailPattern.test(this.emailAddition.campaign);
    },
    openPopUp(id) {
      this.openPop = true;
      this.body = this.emailList.filter((email) => email.id === id);
    },
    addValues(id) {
      if (id == 1) {
        this.start = 0;
        this.end = 10;
      } else if (id == 2) {
        this.start = 11;
        this.end = 20;
      }
    },
  },
};
</script>

<style></style>
