<template>
  <div class="mx-auto py-3 rounded-lg">
    <div class="flex justify-center items-center gap-x-2">
      <button
        @click="prevPage"
        :disabled="currentPage === 1"
        class="w-10 h-10 bg-gray-200 text-center group rounded disabled:opacity-50"
      >
        <b
          :class="{
            'pr-[5px]': currentPage === 1,
            'pr-[0px]': currentPage !== 1,
          }"
          class="group-active:pr-[5px]"
        >
          ❮
        </b>
      </button>

      <p class="text-black">
        Page <b>{{ currentPage }}</b> of
        <b v-if="totalPages">{{ totalPages }}</b>
        <b v-else>{{ currentPage }}</b>
      </p>

      <button
        @click="nextPage"
        :disabled="currentPage === totalPages"
        class="w-10 h-10 bg-gray-200 rounded group disabled:opacity-50"
      >
        <b
          :class="{
            'pl-[5px]': currentPage === totalPages,
            'pl-[0px]': currentPage !== totalPages,
          }"
          class="group-active:pl-[5px]"
          >❯</b
        >
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CrmPagination",
  props: {
    totalItems: Number,
    itemsPerPage: Number,
    currentPage: Number,
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit("updatePage", this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit("updatePage", this.currentPage + 1);
      }
    },
  },
};
</script>
<style scoped>
:disabled {
  z-index: -1;
}
</style>
