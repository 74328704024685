<template>
  <div>
    <div class="w-full md:h-[60px] lg:h-[80px]">
      <TopBar />
    </div>

    <div class="flex h-full w-full">
      <div class="w-[60px] lg:w-[80px] h-full z-20">
        <NavBar />
      </div>
      <div class="w-full h-full pl-16 pt-10 md:px-5 lg:px-8">
        <div
          class="flex flex-col md:flex-row pl-2 gap-2 lg:gap-5 lg:p-4 2xl:p-6 bg-white rounded-lg pt-10 md:pt-0 md:sticky md:top-[80px] z-10"
        >
          <div class="w-full flex-wrap lg:w-[50%] flex gap-2 lg:gap-5">
            <router-link
              :to="{ name: 'campagin' }"
              class="px-2 w-[45%] sm:w-auto 2xl:px-4 rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Campaign',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Campaign',
              }"
            >
              Campaign
            </router-link>
            <router-link
              :to="{ name: 'recipientList' }"
              class="px-2 2xl:px-4 w-[45%] sm:w-auto rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Campaign list',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Campaign list',
              }"
            >
              Campaign List
            </router-link>
            <router-link
              :to="{ name: 'recipient' }"
              class="px-2 2xl:px-4 w-[45%] sm:w-auto rounded-lg flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Recipient',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Recipient',
              }"
            >
              Recipient
            </router-link>
            <router-link
              :to="{ name: 'emailTemplate' }"
              class="px-2 2xl:px-4 rounded-lg w-[45%] sm:w-auto flex items-center h-[36px] 2xl:h-[42px] whitespace-nowrap"
              :class="{
                'bg-[#2563EB] text-white': activeCampaign === 'Email Template',
                'border border-gray-200 bg-white text-gray-700':
                  activeCampaign !== 'Email Template',
              }"
            >
              Email Template
            </router-link>
          </div>

          <div
            class="w-full flex-col md:flex-row md:items-center lg:w-[50%] flex gap-3 2xl:gap-5 justify-end"
          >
            <div class="">
              <form
                @submit.prevent="serachEmailTemplate(emailSearch)"
                class="flex gap-3 items-center lg:gap-x-5"
              >
                <input
                  type="text"
                  class="rounded-lg w-48 sm:w-auto border px-2 py-2 outline-none capitalize"
                  placeholder="Search Names Here"
                  name=""
                  v-model="emailSearch"
                  id="search_email"
                />
                <button
                  type="submit"
                  class="bg-gradient-to-r from-blue-500 to-[#2563EB] capitalize text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-2 2xl:px-4 h-[36px] 2xl:h-[42px] flex items-center rounded-lg"
                >
                  Submit
                </button>
              </form>
            </div>

            <button
              @click="addCampaign"
              class="bg-gradient-to-r w-16 sm:w-auto from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-2 2xl:px-4 h-[36px] 2xl:h-[42px] flex items-center rounded-lg"
            >
              Add +
            </button>
          </div>
          <!-- Email template -->
        </div>
        <div
          v-if="campaginAdding && activeCampaign == 'Email Template'"
          class="fixed inset-0 z-40 flex justify-end"
        >
          <div class="bg-[#2563EB] overflow-y-scroll p-8">
            <div class="flex justify-between items-center mb-4">
              <h2 class="text-xl font-semibold text-white">Send Email</h2>
              <button
                @click="closeOffcanvas"
                class="text-white bg-red-500 px-4 py-2 rounded-lg"
              >
                Close
              </button>
            </div>
            <form
              action=""
              @submit.prevent="sendEmail"
              class="bg-gray-100 p-4 flex flex-col gap-4 rounded-lg"
            >
              <div class="flex flex-col text-start">
                <label for="campaign">Campaign</label>
                <select
                  @change="validateEmail"
                  v-model="emailAddition.campaign"
                  name="mail"
                  id="mail"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full bg-white"
                >
                  <option value="" disabled selected>
                    Select an Campaign *
                  </option>
                  <option
                    v-for="email in campaignList"
                    :key="email.id"
                    :value="email.id"
                  >
                    {{ email.name }}
                  </option>
                </select>
              </div>
              <div class="flex flex-col text-start">
                <label for="subject">Subject</label>
                <input
                  v-model="emailAddition.subject"
                  id="subject"
                  type="text"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                />
              </div>
              <div class="flex flex-col text-start">
                <label for="description">Description</label>

                <textarea
                  name="description"
                  id="description"
                  v-model="emailAddition.body"
                  cols="30"
                  rows="10"
                  class="border focus:outline-none rounded-md px-3 py-2 w-full"
                ></textarea>
              </div>

              <div class="flex justify-center pt-3">
                <button
                  type="submit"
                  class="py-2 w-40 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="w-full pt-8">
          <!-- email start  -->
          <div
            v-if="activeCampaign === 'Email Template'"
            class="max-h-full overflow-x-scroll lg:overflow-x-hidden"
          >
            <table class="w-full whitespace-nowrap mb-4">
              <thead class="text-white">
                <tr class="text-left">
                  <th class="py-4 px-4 bg-[#2563EB] rounded-l-lg">S.No</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Campaign Name</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Subject</th>
                  <th class="py-4 px-4 bg-[#2563EB]">Body</th>
                  <th class="py-4 px-4 bg-[#2563EB] rounded-r-lg">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="message">
                  <td></td>
                  <td class="px-4 py-2 text-left">{{ message }}</td>
                </tr>
                <tr
                  v-else
                  class="text-left border-b"
                  v-for="(email, index) in emailList"
                  :key="index"
                >
                  <td class="p-4">{{ index + 1 }}</td>
                  <td class="p-4">{{ email.campaign.name }}</td>
                  <td class="p-4">{{ email.subject }}</td>
                  <td class="p-4 cursor-pointer" @click="openPopUp(email.id)">
                    View
                  </td>
                  <td class="whitespace-nowrap p-4 relative">
                    <div class="inline-flex items-center justify-center">
                      <button
                        @click="toggleEdit(email.id)"
                        class="btn size-8 rounded-full mt-1 p-0 hover:bg-slate-300/20 focus:bg-slate-300/20 active:bg-slate-300/25 dark:hover:bg-navy-300/20 dark:focus:bg-navy-300/20 dark:active:bg-navy-300/25"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="size-4.5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                          />
                        </svg>
                      </button>
                      <div
                        class="paper absolute -left-[110px] top-4 w-[130px]"
                        v-show="popupId === email.id"
                      >
                        <div
                          class="popper-box rounded-md border border-slate-150 bg-white py-0 font-inter dark:border-navy-500 dark:bg-navy-700"
                        >
                          <ul>
                            <li @click="editEmailTemplate(email.id)">
                              <p
                                class="flex h-6 items-center px-3 pr-4 cursor-pointer font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 text-xs"
                              >
                                Edit
                              </p>
                            </li>
                            <li
                              @click="
                                deleteEmailTemplate(
                                  email.id,
                                  email.campaign.name
                                )
                              "
                            >
                              <p
                                class="flex h-6 items-center px-3 pr-4 cursor-pointer font-medium tracking-wide outline-none transition-all hover:bg-slate-100 hover:text-slate-800 focus:bg-slate-100 focus:text-slate-800 text-xs"
                              >
                                Delete
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!-- <tfoot>
                <tr>
                  <td
                    colspan="100%"
                    class="bg-[#2563EB] px-4 py-2 w-full rounded-lg"
                  >
                    <div class="flex justify-between items-center">
                      <div class="h-[32px] flex items-center justify-center">
                        <button
                          @click="prevPage"
                          :disabled="currentPage === 1"
                          class="text-black flex justify-center items-center h-full px-4 bg-white rounded-lg cursor-pointer"
                          style="height: 36px; line-height: 36px"
                        >
                          <span class="pt-[2px]"> Previous</span>
                        </button>
                      </div>

                      <p class="text-white">
                        Page {{ currentPage }} of {{ totalPages }}
                      </p>
                      <div class="h-[32px] flex items-center justify-center">
                        <button
                          @click="nextPage"
                          :disabled="currentPage === totalPages"
                          class="text-black px-4 flex items-center justify-center h-full bg-white rounded-lg cursor-pointer"
                          style="height: 36px; line-height: 36px"
                        >
                          <span class="pt-[2px]">Next</span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot> -->
            </table>
          </div>
          <CrmPagination
            :totalItems="email_count"
            :itemsPerPage="itemsPerPage"
            :currentPage="currentPage"
            @updatePage="updatePage"
          />
          <!-- email end  -->
        </div>
        <div
          v-if="openPop"
          class="z-50 flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-80"
        >
          <div
            class="!flex justify-center !items-center w-[60%] h-[90%] bg-white mx-auto rounded-lg relative !z-999 p-5 overflow-y-scroll pt-5"
          >
            <button
              @click="openPop = false"
              class="absolute top-0 right-0 p-2 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
            >
              <svg
                class="w-6 h-6 text-gray-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            <div
              v-for="(email, index) in body"
              :key="index"
              class="overflow-y-scroll p-5 h-[90%]"
            >
              <div
                class="flex flex-wrap text-left"
                v-html="email.body"
                style="white-space: normal; word-wrap: break-word"
              ></div>
            </div>
          </div>
        </div>

        <!-- delete email template -->
        <div
          v-if="deleteEmail == true"
          class="z-50 flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-80"
        >
          <div
            class="!flex justify-center !items-center bg-white mx-auto w-[500px] h-[200px] rounded-lg relative !z-999 p-5 overflow-y-scroll pt-5 text-3xl font-bold text-black"
          >
            <div class="flex flex-col gap-3">
              <h3>Are You Sure Want To Delete</h3>
              <p class="text-xl text-gray-700">{{ campagin_name }}</p>
              <div class="flex justify-center gap-10 text-lg">
                <button
                  @click="deleteEmailstatus"
                  class="text-white bg-green-400 px-3 py-1.5 rounded-lg"
                >
                  Delete
                </button>
                <button
                  @click="deleteEmail = false"
                  class="text-white bg-red-400 px-3 py-1.5 rounded-lg"
                >
                  Cancle
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- edit email template -->
        <div
          v-if="editEmailStatus == true"
          class="z-50 flex items-center justify-center fixed top-0 left-0 w-full h-full bg-black bg-opacity-80"
        >
          <div
            class="!flex justify-center !items-center bg-white mx-auto w-[80%] h-[90%] rounded-lg relative !z-999 p-5 overflow-y-scroll text-3xl font-bold text-black"
          >
            <div class="p-8 w-full text-lg h-full">
              <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold text-black">Update Email</h2>
                <button
                  @click="editEmailStatus = false"
                  class="text-white bg-red-500 px-4 py-2 rounded-lg"
                >
                  Close
                </button>
              </div>
              <form
                action=""
                @submit.prevent="updateEmailTemplate"
                class="p-4 flex flex-col gap-4 rounded-lg !text-gray-500"
              >
                <div class="flex flex-col text-start">
                  <label for="campaign">Campaign</label>
                  <select
                    @change="validateEmail"
                    v-model="updateEmail.campaign"
                    name="mail"
                    id="mail"
                    class="border focus:outline-none rounded-md px-3 py-2 w-full bg-white"
                  >
                    <option value="" disabled selected>
                      Select an Campaign *
                    </option>
                    <option
                      v-for="email in campaignList"
                      :key="email.id"
                      :value="email.id"
                    >
                      {{ email.name }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-col text-start">
                  <label for="subject">Subject</label>
                  <input
                    v-model="updateEmail.subject"
                    id="subject"
                    type="text"
                    class="border focus:outline-none rounded-md px-3 py-2 w-full"
                  />
                </div>
                <div class="flex flex-col text-start">
                  <label for="description">Description</label>

                  <textarea
                    name="description"
                    id="description"
                    v-model="updateEmail.body"
                    cols="30"
                    rows="10"
                    class="border focus:outline-none rounded-md px-3 py-2 w-full"
                  ></textarea>
                </div>

                <div class="flex justify-center pt-3">
                  <button
                    type="submit"
                    class="py-2 w-40 rounded-lg bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB]"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <div v-if="updateValue" class="py-3">
                <p class="text-lg font-semibold">{{ updateValue }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import TopBar from "@/components/TopBar.vue";
import CrmPagination from "@/components/CrmPagination.vue";
export default {
  name: "CampaginView",
  components: {
    NavBar,
    TopBar,
    CrmPagination,
  },
  data() {
    return {
      deleteEmail: false,
      editEmailStatus: false,
      emailToDelete: "",
      popupId: "",
      activeCampaign: "",
      campaginAdding: false,
      start: 0,
      end: 10,
      campaignList: [],
      emailList: [],
      email_count: "",
      openPop: false,
      emailAddition: {
        campaign: "",
        subject: "",
        body: "",
      },
      body: "",
      emailResponse: "",
      currentPage: 1,
      itemsPerPage: 0,
      campagin_name: "",
      emailUpdate: [],
      emailIdToUpdate: "",
      updateEmail: {
        campaign: "",
        subject: "",
        body: "",
      },
      updateValue: "",
      message: "",
    };
  },
  async created() {
    await this.getCampaign();
    await this.getEmail(this.currentPage);
    let url = this.$route.path;
    if (url == "/campagin-dashboard/email-template") {
      this.activeCampaign = "Email Template";
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.email_count / this.itemsPerPage);
    },
    // paginatedRecipients() {
    //   if (this.recipientList.length > 0) {
    //     const start = (this.currentPage - 1) * this.itemsPerPage;
    //     const end = start + this.itemsPerPage;
    //     return this.recipientList.slice(start, end);
    //   }
    //   return [];
    // },
  },
  methods: {
    toggleEdit(id) {
      if (this.popupId === id) {
        this.popupId = null;
      } else {
        this.popupId = id;
      }
    },
    async getCampaign() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/campaign/list/`)
        .then((resp) => {
          if (resp.status == 200) {
            this.campaignList = resp.data.data;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
    },
    // async getEmail() {
    //   await axios
    //     .get(`${process.env.VUE_APP_API}/api/email-template/list/`)
    //     .then((resp) => {
    //       if (resp.status == 200) {
    //         this.emailList = resp.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("error : ", err);
    //     });
    // },
    async getEmail(val) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/api/email-template/list/`,
          {
            params: {
              page: val,
            },
          }
        );

        if (response.data.status === 200) {
          this.emailList = response.data.data;
          this.email_count = response.data.count;
          this.itemsPerPage = response.data.page_size;
          this.currentPage = val;
          console.log("campaign list:", this.itemsPerPage);
          console.log("Current Page:", this.recipient_count);
        } else {
          console.error("Unexpected status code:", response.data.status);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },

    async updatePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        await this.getEmail(page);
      }
    },
    serachEmailTemplate(name) {
      console.log("entered serachEmailTemplate");
      axios
        .get(
          `${process.env.VUE_APP_API}/api/email-template/list/?search=${name}`
        )
        .then((resp) => {
          if (resp.data.status == 200) {
            this.emailList = resp.data.data;
          } else if (resp.data.status == 400) {
            this.message = resp.data.message;
          }
        })
        .catch((err) => {
          console.log("error : ", err);
        });
      console.log("exited serachEmailTemplate");
    },
    deleteEmailTemplate(id, name) {
      this.deleteEmail = true;
      this.campagin_name = name;
      this.emailToDelete = id;
    },
    deleteEmailstatus() {
      this.deleteEmail = false;
      this.campagin_name = "";
      axios
        .delete(
          `${process.env.VUE_APP_API}/api/email-template/update-destroy/${this.emailToDelete}/`
        )
        .then((resp) => {
          if (resp.status == 200) {
            if (this.campaignList.length === 1 && this.currentPage > 1) {
              this.currentPage--;
            }
            this.getEmail(this.currentPage);
            this.updatePage(this.currentPage);
            this.totalPages();
            this.popupId = "";
          }
        })
        .catch((err) => {
          console.log("error ", err);
        });
      this.deleteEmail = false;
      this.campagin_name = "";
    },
    // delete campaign end
    sendEmail() {
      this.editEmailStatus = false;
      axios
        .post(
          `${process.env.VUE_APP_API}/api/email-template-create/post/`,
          this.emailAddition
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.getEmail(this.currentPage);
            this.closeOffcanvas();
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // edit campaign start
    editEmailTemplate(id) {
      this.emailUpdate = this.emailList.filter(
        (emailTem) => id === emailTem.id
      )[0];
      this.updateEmail = {
        campaign: this.emailUpdate.campaign,
        subject: this.emailUpdate.subject,
        body: this.emailUpdate.body,
      };
      this.editEmailStatus = true;
      this.emailIdToUpdate = id;
    },
    updateEmailTemplate() {
      this.updateValue = "";

      axios
        .patch(
          `${process.env.VUE_APP_API}/api/email-template/update-destroy/${this.emailIdToUpdate}/`,
          this.updateEmail
        )
        .then((resp) => {
          if (resp.status == 200) {
            this.updateValue = resp.data.message;
            console.log("campagin response value on update:", resp);
            console.log(
              "campagin this.updateValue value on update:",
              this.updateValue
            );
            setTimeout(() => {
              this.getEmail();
              this.editEmailStatus = false;
              this.popupId = null;
              this.updateValue = "";
            }, 3000);
          }
        })
        .catch((err) => {
          console.log("error ", err);
        });
    },
    // edit campaign end
    addCampaign() {
      this.campaginAdding = true;
    },
    closeOffcanvas() {
      this.campaginAdding = false;
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailValid = emailPattern.test(this.emailAddition.campaign);
    },
    openPopUp(id) {
      this.openPop = true;
      this.body = this.emailList.filter((email) => email.id === id);
    },
    addValues(id) {
      if (id == 1) {
        this.start = 0;
        this.end = 10;
      } else if (id == 2) {
        this.start = 11;
        this.end = 20;
      }
    },
    // nextPage() {
    //   if (this.currentPage < this.totalPages) {
    //     this.currentPage++;
    //   }
    // },
    // prevPage() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;
    //   }
    // },
  },
};
</script>

<style scoped>
.svg-icon {
  color: white;
}
</style>
