<template>
  <div>
    <div class="w-full md:h-[60px] lg:h-[80px]">
      <TopBar />
    </div>

    <div class="flex h-full w-full">
      <div class="w-[60px] lg:w-[80px] h-full z-20">
        <NavBar />
      </div>
      <div class="w-full h-full pl-16 pt-10 pr-3 md:px-5 lg:px-8">
        <div
          class="flex flex-col md:flex-row pl-2 gap-2 lg:gap-5 lg:p-4 2xl:p-6 bg-white rounded-lg pt-10 md:pt-0 md:sticky md:top-[80px] z-10"
        >
          <div class="w-full flex md:justify-end">
            <form
              @submit.prevent="serachRequest(demoName)"
              class="flex gap-3 items-center lg:gap-x-5"
            >
              <input
                type="text"
                class="rounded-lg w-48 sm:w-auto border px-2 py-2 outline-none capitalize"
                placeholder="Search Names Here"
                v-model="demoName"
              />
              <button
                type="submit"
                class="bg-gradient-to-r from-blue-500 to-[#2563EB] text-white hover:bg-gradient-to-l hover:from-blue-500 hover:to-[#2563EB] font-bold px-2 2xl:px-4 h-[36px] 2xl:h-[42px] flex items-center rounded-lg"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
        <div class="w-full pt-8 overflow-x-scroll lg:overflow-hidden">
          <!-- Book a demo list start -->
          <div class="max-h-full">
            <table class="w-full h-[90%] whitespace-nowrap mb-4">
              <thead class="text-white">
                <tr class="text-left">
                  <th class="p-4 bg-[#2563EB] rounded-l-lg">S.No</th>
                  <th class="p-4 bg-[#2563EB]">Name</th>
                  <th class="p-4 bg-[#2563EB]">Email</th>
                  <th class="p-4 bg-[#2563EB]">Phone Number</th>
                  <th class="p-4 bg-[#2563EB]">Slot</th>
                  <th class="p-4 bg-[#2563EB]">Company</th>
                  <th class="p-4 bg-[#2563EB]">Date</th>
                  <th class="p-4 bg-[#2563EB]">Message</th>
                  <th class="p-4 bg-[#2563EB] rounded-r-lg">Survey</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="message">
                  <td></td>
                  <td class="px-4 py-2 text-left">{{ message }}</td>
                </tr>
                <tr
                  v-else
                  class="text-left border-b"
                  v-for="(request, index) in requestList"
                  :key="index"
                >
                  <td class="px-4 py-2">
                    {{ index + 1 + (currentPage - 1) * itemsPerPage }}
                  </td>
                  <td class="px-4 py-2">{{ request.name }}</td>
                  <td class="px-4 py-2">{{ request.email }}</td>
                  <td class="px-4 py-2">{{ request.phone_number }}</td>
                  <td class="px-4 py-2">{{ request.demo_time.slot }}</td>
                  <td class="px-4 py-2">{{ request.company_name }}</td>
                  <td class="px-4 py-2">{{ formatDate(request.demo_date) }}</td>
                  <td class="px-4 py-2">
                    <span v-if="request.message">{{ request.message }}</span>
                    <span v-else>-</span>
                  </td>
                  <td
                    class="px-4 py-2"
                    @click="openSurvey(request.demo_survey)"
                  >
                    <span class="bg-[#2563EB] px-2 py-1 text-white rounded-md"
                      >View</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <CrmPagination
              :totalItems="request_count"
              :itemsPerPage="itemsPerPage"
              :currentPage="currentPage"
              @updatePage="updatePage"
            />
          </div>
        </div>
      </div>
      <div v-if="showOffcanvas" class="fixed inset-0 z-40 flex justify-end">
        <div class="bg-[#2563EB] w-full overflow-y-scroll sm:max-w-[440px] p-8">
          <div class="flex justify-between items-center mb-4">
            <h2 class="text-xl font-semibold text-white">Survey Details</h2>
            <button
              @click="showOffcanvas = false"
              class="text-white bg-red-500 px-4 py-2 rounded-lg"
            >
              Close
            </button>
          </div>
          <!-- Content -->
          <div class="border p-2 bg-white rounded-md flex flex-col gap-3">
            <h1 class="w-full text-start flex flex-col gap-2">
              Full Name :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
                >{{
                  surveyDetails.full_name ? surveyDetails.full_name : "Nill"
                }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              write_to :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{ surveyDetails.write_to ? surveyDetails.write_to : "Nill" }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              job_role :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{ surveyDetails.job_role ? surveyDetails.job_role : "Nill" }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              company_called :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{
                  surveyDetails.company_called
                    ? surveyDetails.company_called
                    : "Nill"
                }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              Team size :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{ surveyDetails.team_size ? surveyDetails.team_size : "Nill" }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              sector :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{ surveyDetails.sector ? surveyDetails.sector : "Nill" }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              key_obstacles :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{
                  surveyDetails.key_obstacles
                    ? surveyDetails.key_obstacles
                    : "Nill"
                }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              aim_achieve :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{
                  surveyDetails.aim_achieve ? surveyDetails.aim_achieve : "Nill"
                }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              tool_using :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{
                  surveyDetails.tool_using ? surveyDetails.tool_using : "Nill"
                }}
              </span>
            </h1>
            <h1 class="w-full text-start flex flex-col gap-2">
              new_enhancements :
              <span
                class="border focus:outline-none rounded-md px-3 py-2 w-full"
              >
                {{
                  surveyDetails.new_enhancements
                    ? surveyDetails.new_enhancements
                    : "Nill"
                }}
              </span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import NavBar from "@/components/NavBar.vue";
import TopBar from "@/components/TopBar.vue";
import CrmPagination from "@/components/CrmPagination.vue";

export default {
  name: "BookademoRequest",
  components: {
    NavBar,
    TopBar,
    CrmPagination,
  },
  data() {
    return {
      start: 0,
      end: 10,
      requestList: [],
      currentPage: 1,
      request_count: "",
      itemsPerPage: 0,
      message: "",
      showOffcanvas: false,
      surveyDetails: [],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.request_count / this.itemsPerPage);
    },
    // paginatedList() {
    //   const start = (this.currentPage - 1) * this.itemsPerPage;
    //   const end = start + this.itemsPerPage;
    //   return this.requestList.slice(start, end);
    // },
  },
  async created() {
    await this.getRequestList(this.currentPage);
    await this.getSurvey();
  },
  methods: {
    async getSurvey() {
      await axios
        .get(`${process.env.VUE_APP_API}/api/user-survey/list/`)
        .then((resp) => {
          if (resp.status === 200) {
            this.surveyDetails = resp.data.data;
            console.log("surveyyyy---------------", this.surveyDetails);
          }
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    },
    openSurvey(survey) {
      this.surveyDetails = survey;
      console.log("Survey Details:", this.surveyDetails);
      this.showOffcanvas = true;
    },
    closeOffcanvas() {
      this.showOffcanvas = false;
    },
    async getRequestList(val) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/api/book-a-demo/list/`,
          {
            params: {
              page: val,
            },
          }
        );

        if (response.data.status === 200) {
          this.requestList = response.data.data;
          this.request_count = response.data.count;
          this.itemsPerPage = response.data.page_size;
          this.currentPage = val;
          console.log("requestper page:", this.itemsPerPage);
          console.log("Current Page:", this.currentPage);
        } else {
          console.error("Unexpected status code:", response.data.status);
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    },
    async updatePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        await this.getRequestList(page);
      }
    },
    serachRequest(name) {
      axios
        .get(`${process.env.VUE_APP_API}/api/book-a-demo/list/?search=${name}`)
        .then((resp) => {
          if (resp.data.status === 200) {
            this.requestList = resp.data.data;
          } else if (resp.data.status === 400) {
            this.message = resp.data.message;
          }
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    },
    // updatePage(page) {
    //   this.currentPage = page;
    // },
    formatDate(dateString) {
      if (!dateString) return "Invalid Date";
      const date = new Date(dateString);

      const dateOptions = { month: "short", day: "2-digit", year: "numeric" };
      const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formattedDate = date.toLocaleDateString("en-IN", dateOptions);
      const formattedTime = date.toLocaleTimeString("en-IN", timeOptions);

      return `${formattedDate} / ${formattedTime}`;
    },
  },
};
</script>

<style scoped>
.svg-icon {
  color: white;
}
</style>
